@import "../color.scss";
@import "../constants.scss";

#verify-code-main {
    font-family: googleSans, "Open Sans", sans-serif;
    background: $white;
    width: 100%;
    height: calc(100vh - #{$header-height});
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;

    .verify-code-container {
        display: flex;
        flex-direction: column;
        align-items: center;
        max-width: 412px;

        .verification-code-input {
            margin: 30px 0 85px;

            input {
                width: 45px;
                border-radius: 0px;
                font-size: 35px;
                line-height: 50px;
                height: 60px;
                padding: 5px 10px;
                background-color: none;
                color: $active-black;
                border: none;
                border-bottom: 3px solid $light-blue;
            }

            input:not(:last-child) {
                margin-right: 12px;
            }

            input:focus-visible {
                outline-style: none;
            }

            /* Chrome, Safari, Edge, Opera */
            input::-webkit-outer-spin-button,
            input::-webkit-inner-spin-button {
                -webkit-appearance: none;
                margin: 0;
            }

            /* Firefox */
            input {
                -moz-appearance: textfield;
            }
        }

        .have-account-login-text {
            color: $light-blue;
            text-align: center;
            font-size: 12px;
            font-style: normal;
            font-weight: 400;
            line-height: normal;
        }

        .base-link span {
            color: $primary-blue;
            text-align: center;
            font-size: 12px;
            font-style: normal;
            font-weight: 400;
            line-height: normal;
        }
    }

    .resend-link {
        color: $primary-blue;
        text-align: center;
        cursor: pointer;
        font-size: 12px;
        font-style: normal;
        font-weight: 400;
        line-height: normal;
        text-decoration: underline;
    }

    .verify-title {
        color: $active-black;
        text-align: center;
        font-size: 20px;
        font-style: normal;
        font-weight: 700;
        line-height: normal;
    }

    .verify-desc {
        color: $active-black;
        text-align: center;
        font-size: 12px;
        font-style: normal;
        font-weight: 400;
        line-height: normal;
    }
}

@media only screen and (max-width: 768px) {
    #verify-code-main {
        height: calc(100dvh - #{$header-height-mobile});
    }
}