@import "../color.scss";

.base-link {
    color: $primary-blue;
    font-size: 15px;
    text-decoration: underline;

    span {
        text-decoration: underline;
    }

    &:hover {
        color: $primary-blue;
    }
}