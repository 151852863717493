.ibm-plex-sans-thin {
	font-family: "IBM Plex Sans", sans-serif;
	font-weight: 100;
	font-style: normal;
}

.ibm-plex-sans-extralight {
	font-family: "IBM Plex Sans", sans-serif;
	font-weight: 200;
	font-style: normal;
}

.ibm-plex-sans-light {
	font-family: "IBM Plex Sans", sans-serif;
	font-weight: 300;
	font-style: normal;
}

.ibm-plex-sans-regular {
	font-family: "IBM Plex Sans", sans-serif;
	font-weight: 400;
	font-style: normal;
}

.ibm-plex-sans-medium {
	font-family: "IBM Plex Sans", sans-serif;
	font-weight: 500;
	font-style: normal;
}

.ibm-plex-sans-semibold {
	font-family: "IBM Plex Sans", sans-serif;
	font-weight: 600;
	font-style: normal;
}

.ibm-plex-sans-bold {
	font-family: "IBM Plex Sans", sans-serif;
	font-weight: 700;
	font-style: normal;
}

.ibm-plex-sans-thin-italic {
	font-family: "IBM Plex Sans", sans-serif;
	font-weight: 100;
	font-style: italic;
}

.ibm-plex-sans-extralight-italic {
	font-family: "IBM Plex Sans", sans-serif;
	font-weight: 200;
	font-style: italic;
}

.ibm-plex-sans-light-italic {
	font-family: "IBM Plex Sans", sans-serif;
	font-weight: 300;
	font-style: italic;
}

.ibm-plex-sans-regular-italic {
	font-family: "IBM Plex Sans", sans-serif;
	font-weight: 400;
	font-style: italic;
}

.ibm-plex-sans-medium-italic {
	font-family: "IBM Plex Sans", sans-serif;
	font-weight: 500;
	font-style: italic;
}

.ibm-plex-sans-semibold-italic {
	font-family: "IBM Plex Sans", sans-serif;
	font-weight: 600;
	font-style: italic;
}

.ibm-plex-sans-bold-italic {
	font-family: "IBM Plex Sans", sans-serif;
	font-weight: 700;
	font-style: italic;
}
