@import "../color.scss";

.fixed-info-footer {
    position: fixed;
    bottom: 0;
    height: fit-content;
    align-items: flex-start;
    max-width: 961px;
    width: 100%;
    padding: 20px;
    border-radius: 8px 8px 0px 0px;
    border: 1px solid $off-white;
    background: #FEFEFE;
    box-shadow: 0px 4px 16px 0px rgba(25, 37, 65, 0.16);


    .footer-title {
        color: $active-black;
        text-align: left;
        font-size: 20px;
        font-style: normal;
        font-weight: 700;
        line-height: normal;
        max-width: 600px;
    }

    .footer-desc {
        margin-top: 5px;
        color: $active-black;
        text-align: left;
        font-size: 12px;
        font-style: normal;
        font-weight: 400;
        line-height: normal;
        max-width: 600px;
    }
}

@media only screen and (max-width: 768px) {
    .fixed-info-footer {
        width: 100%;

        .footer-desc {
            max-width: 100%;
        }

        .footer-title {
            max-width: 100%;
        }
    }
}