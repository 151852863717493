#external-account-items {
    .external-sso-button {
        height: 40px;
        width: 40px;

        .social-icon {
            height: 18px;
        }

        margin: 0 12px;

        border-radius: 4px;
        cursor: pointer;
    }

    .external-sso-button-border {
        border: 1px solid #dadce0;
        color: #3c4043;
    }

    .items-row {
        margin-top: 12px;
    }
}