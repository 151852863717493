@import "../color.scss";


.Toastify__toast-theme--light {
	background: $white;
}

.Toastify__toast-icon {
	display: none;
	width: 20px;
	margin-right: 25px;
}

.toast-icon {
	width: 20px;
	margin-right: 25px;
}

.Toastify__toast-container--top-right {
	top: 70px;
}

.Toastify__toast {
	border-radius: 8px;
	background: #fff;
	box-shadow: 0px 4px 4px 0px rgba(56, 120, 232, 0.16);
	padding: 10px 15px;
}

@media only screen and (max-width: 768px) {
	.Toastify__toast-container {
		width: 90vw;
		padding: 0;
		left: 5vw;
		margin: 0;
	}
}