@import "../color.scss";
@import "../constants.scss";

#signup-main {
    font-family: googleSans, "Open Sans", sans-serif;
    background: $white;
    width: 100%;
    height: calc(100vh - #{$header-height});
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    padding-top: 40px;
    align-items: center;

    .send-verif-code-btn {
        span {
            color: $white;
            font-size: 15px;
            font-style: normal;
            font-weight: 400;
            line-height: normal;
        }
    }


    .signup-container {
        display: flex;
        flex-direction: column;
        align-items: center;
        max-width: 337px;

        .field-container {
            align-items: flex-start;
            margin-bottom: 20px;

            .field-title {
                color: $dark-blue;
                font-size: 12px;
                font-style: normal;
                font-weight: 500;
                line-height: normal;
                margin-bottom: 6px;
            }
        }

        .have-account-login-text {
            color: $light-blue;
            text-align: center;
            font-size: 12px;
            font-style: normal;
            font-weight: 400;
            line-height: normal;
        }


        .base-link span {
            color: $primary-blue;
            text-align: center;
            font-size: 12px;
            font-style: normal;
            font-weight: 400;
            line-height: normal;
        }
    }

    .container-title {
        color: $active-black;
        text-align: center;
        font-size: 20px;
        font-style: normal;
        font-weight: 700;
        line-height: normal;
    }

    .container-desc {
        color: $active-black;
        text-align: center;
        font-size: 12px;
        font-style: normal;
        font-weight: 400;
        line-height: normal;
    }

    .signup-landing-container {
        display: flex;
        flex-direction: column;
        align-items: center;
        max-width: 328px;

        .container-desc {
            margin-top: 20px;
            margin-bottom: 20px;
        }

        .signup-via-email-btn {
            span {
                color: $white;
                font-size: 15px;
                font-style: normal;
                font-weight: 400;
                line-height: normal;
            }
        }

        .or {
            margin-top: 20px;
            margin-bottom: 20px;
            color: $light-blue;
            text-align: center;
            font-size: 12px;
            font-style: normal;
            font-weight: 400;
            line-height: normal;
        }

        .share-options {
            margin-bottom: 20px;
        }

        .dont-have-account-text {
            color: $light-blue;
            text-align: center;
            font-size: 12px;
            font-style: normal;
            font-weight: 400;
            line-height: normal;
        }

        .base-link span {
            color: $primary-blue;
            text-align: center;
            font-size: 12px;
            font-style: normal;
            font-weight: 400;
            line-height: normal;

        }
    }
}

@media only screen and (max-width: 768px) {
    #signup-main {
        height: calc(100dvh - #{$header-height-mobile});
    }
}