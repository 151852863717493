@import "../../styles/color.scss";

.base-input-general {
	margin-bottom: 10px;

	.error-text {
		color: red;
		font-size: 13px;
		margin-top: 5px;
	}
}

.base-input {
	font-size: 15px;
	font-style: normal;
	font-weight: 400;
	line-height: normal;
	border-radius: 4px;
	border: 1px solid $tertiary-text-color;
	background: #fff;
	padding: 10px 15px;


	&::placeholder {
		color: $passive-gray;
	}

}

// if new-base-input type is date
.base-input[type="date"] {
	box-sizing: border-box;
	background-image: url('../../assets/images/icons/custom-date-icon.svg');
	background-repeat: no-repeat;
	background-position: right center;
	background-size: 15px;
	background-position: center right 15px;

	&::-webkit-calendar-picker-indicator {
		display: none;
	}
}

.base-input[type="date"]:focus-visible {
	border-radius: 4px;
	background: #fff;
	border: 1px solid $active-black;
	outline: none;

	box-sizing: border-box;
	background-image: url('../../assets/images/icons/custom-date-icon.svg');
	background-repeat: no-repeat;
	background-position: right center;
	background-size: 15px;
	background-position: center right 15px;

	&::placeholder {
		color: $active-black;
	}

	&::-webkit-calendar-picker-indicator {
		display: none;
	}
}

.base-input[type="date"]:active {
	border-radius: 4px;
	background: #fff;
	border: 1px solid $active-black;

	box-sizing: border-box;
	background-image: url('../../assets/images/icons/custom-date-icon.svg');
	background-repeat: no-repeat;
	background-position: right center;
	background-size: 15px;
	background-position: center right 15px;

	&::placeholder {
		color: $active-black;
	}

	&::-webkit-calendar-picker-indicator {
		display: none;
	}
}


.suffix-icon {
	position: absolute;
	right: 15px;
}

.prefix-icon {
	position: absolute;
	left: 15px;
}

.visibility-button {
	position: absolute;
	cursor: pointer;
	right: 15px;
}

.base-input:focus {
	border-radius: 4px;
	background: #fff;
	border: 1px solid $active-black;

	&::placeholder {
		color: $active-black;
	}
}


.base-input:focus-visible {
	border-radius: 4px;
	background: #fff;
	border: 1px solid $active-black;
	outline: none;

	&::placeholder {
		color: $active-black;
	}

}



.base-input:active {
	border-radius: 4px;
	background: #fff;
	border: 1px solid $active-black;

	&::placeholder {
		color: $active-black;
	}

}

.error {
	border: 1px solid red;
	color: red;
	border-radius: 4px;


	&[type="date"] {
		box-sizing: border-box;
		background-image: url('../../assets/images/icons/custom-date-icon-error.svg');
		background-repeat: no-repeat;
		background-position: right center;
		background-size: 15px;
		background-position: center right 15px;
	}

	&[type="date"]:active {
		box-sizing: border-box;
		background-image: url('../../assets/images/icons/custom-date-icon-error.svg');
		background-repeat: no-repeat;
		background-position: right center;
		background-size: 15px;
		background-position: center right 15px;
	}

	&[type="date"]:focus {
		box-sizing: border-box;
		background-image: url('../../assets/images/icons/custom-date-icon-error.svg');
		background-repeat: no-repeat;
		background-position: right center;
		background-size: 15px;
		background-position: center right 15px;
	}

	&[type="date"]:focus-visible {
		box-sizing: border-box;
		background-image: url('../../assets/images/icons/custom-date-icon-error.svg');
		background-repeat: no-repeat;
		background-position: right center;
		background-size: 15px;
		background-position: center right 15px;
	}

	&:active {
		border: 1px solid red;
		color: red;
		border-radius: 4px;

		&::placeholder {
			color: red;
		}
	}

	&:focus {
		border: 1px solid red;
		color: red;
		border-radius: 4px;

		&::placeholder {
			color: red;
		}
	}

	&:focus-visible {
		border: 1px solid red;
		color: red;
		border-radius: 4px;

		&::placeholder {
			color: red;
		}
	}

	&::placeholder {
		color: red;
	}
}

.success {
	border: 1px solid $success-green;
}

.character-counter {
	position: absolute;
	bottom: -23px;
	right: 0;
	color: $helper-text-gray;
	text-align: right;
	font-size: 12px;
	font-style: normal;
	font-weight: 400;
	line-height: normal;
}

.helper-text {
	margin-top: 6px;
	color: $helper-text-gray ;
	font-family: Google Sans;
	font-size: 12px;
	font-style: normal;
	font-weight: 400;
}

.success-text {
	margin-top: 6px;
	color: $success-green;
	font-family: Google Sans;
	font-size: 12px;
	font-style: normal;
	font-weight: 400;
}