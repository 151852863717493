@import "./color.scss";
@import '~bootstrap/scss/bootstrap';

body {
	margin: 0;
	font-family: googleSans, -apple-system, BlinkMacSystemFont,
		"Segoe UI", "Roboto", "Oxygen", "Ubuntu", "Cantarell", "Fira Sans",
		"Droid Sans", "Helvetica Neue", "Roboto" sans-serif;
	-webkit-font-smoothing: antialiased;
	-moz-osx-font-smoothing: grayscale;
	font-family: googleSans, "Roboto";
	width: 100%;
	height: 100%;
	font-synthesis: none;
	background-color: $bg-white;
	// For Bootstrap tooltip
	--bs-font-sans-serif: googleSans, "Open Sans", sans-serif !important;
	--bs-body-font-family: googleSans, "Open Sans", sans-serif !important;

	.tooltip {
		font-family: var(--bs-font-sans-serif) !important;
	}
}

html {
	width: 100%;
	height: 100%;
	background-color: $bg-white;
}

#root,
.app {
	width: 100%;
	min-height: 100vh;
	height: fit-content;
	background-color: $white;
}

/* #root>div{
  height: 100vh;
} */

::-webkit-scrollbar {
	display: none;
}

code {
	font-family: googleSans, source-code-pro, Menlo, Monaco, Consolas,
		"Courier New", monospace;
}

a {
	text-decoration: none !important;

	&:focus,
	&:hover,
	&:visited,
	&:link,
	&:active {
		text-decoration: none !important;
		color: inherit !important;
	}

	color: inherit !important;
}

.navbar-container {
	align-items: center;
	display: flex;
	width: 5vw;
	padding-left: 10px;
	padding-right: 10px;
	padding-top: 5px;
	justify-self: center;
}

.main-container {
	background-color: $bg-white;
	flex-direction: column;
	height: 100vh;
}

#login-btn {
	border: solid;
	border-radius: 40px;
	border-color: white;
	display: flex;
	width: 200px;
	align-items: center;
	justify-content: center;
	height: 75px;
	border-width: 0.5px;
}

#signup-btn {
	height: 75px;
	width: 200px;
	display: flex;
	border-radius: 40px;
	align-items: center;
	justify-content: center;
	background-color: rgb(63, 192, 198);
	margin: 0px 100px 0px 20px;
}

.btn-text {
	font-family: Roboto;
	font-size: 18px;
	font-weight: bold;
	color: white;
}

.landing-title {
	font-size: 75px;
	font-weight: bold;
	color: white;
	font-family: Roboto;
}

.landing-exp {
	font-size: 24px;
	font-weight: lighter;
	color: white;
}

.flex-row {
	display: flex;
	flex-direction: row;
	justify-content: flex-start;
	align-items: center;
}

.flex-column {
	display: flex;
	flex-direction: column;
	justify-content: flex-start;
	align-items: center;
}

.jc-sb {
	justify-content: space-between !important;
}

.jc-c {
	justify-content: center !important;
}

.jc-fe {
	justify-content: flex-end !important;
}

.jc-sa {
	justify-content: space-around !important;
}

.ai-c {
	align-items: center !important;
}

.ai-fs {
	align-items: flex-start !important;
}

.ai-fe {
	align-items: flex-end !important;
}

.s1 {
	font-size: 60px;
	line-height: 75px;
	color: $black;
}

.s2 {
	font-size: 50px;
	line-height: 62px;
	color: $black;
}

.s3 {
	font-size: 40px;
	line-height: 60px;
	color: $black;
}

.s3-5 {
	font-size: 26px;
	line-height: 40px;
	color: $black;
}

.s4-5 {
	font-size: 25px;
	line-height: 32px;
	color: $black;
}

.s4 {
	font-size: 24px;
	line-height: 30px;
	color: $black;
}

.s5 {
	font-size: 20px;
	line-height: 25px;
	color: $black;
}

.s5-5 {
	font-size: 18px;
	line-height: 22px;
	color: $black;
}

.s6-5 {
	font-size: 17px;
	line-height: 25px;
	color: $black;
	font-weight: 400;
}

.s6 {
	font-size: 16px;
	line-height: 20px;
	color: $black;
	font-weight: 400;
}

.s7-5 {
	font-size: 15px;
	line-height: 22px;
	color: $black;
	font-weight: 400;
}

.s7 {
	font-size: 14px;
	line-height: 17px;
	color: $black;
	font-weight: 400;
}

.s8-5 {
	font-size: 13px;
	line-height: 15px;
	color: $black;
	font-weight: 400;
}

.s8 {
	font-size: 12px;
	line-height: 15px;
	color: $black;
	font-weight: 400;
}

.s9 {
	font-size: 10px;
	line-height: 12px;
	color: $black;
	font-weight: 400;
}

.boldish {
	font-weight: 700;
}

.medium {
	font-weight: 500;
}

.italic {
	font-style: italic;
}

.underlined {
	text-decoration: underline;
	cursor: pointer;
}

.cursor-pointer {
	cursor: pointer;
}

.blackish {
	color: $black;
}

.orangish {
	color: $orange;
}

.whiteish {
	color: $white;
}

.grayish {
	color: $text-gray;
}

.blueish {
	color: $primary-blue !important;
}

.light-blue {
	color: $light-blue !important;
}

.passive-gray {
	color: $passive-gray !important;
}

input:not(.base-input) :not(.verification-code-input)>input {
	border: none !important;
	border-color: transparent !important;
	background-color: transparent;
	overflow: hidden;
	outline: none;

	&:active {
		outline: none;
		border: none !important;
		border-color: transparent !important;
	}
}

.no-pointer-event {
	pointer-events: none !important;
}

.pointer-events-active {
	pointer-events: auto !important;
}

@media only screen and (max-width: 768px) {
	.s1 {
		font-size: 25px;
		line-height: 25px;
		color: $black;
	}

	.s1-5 {
		font-size: 24px;
		line-height: 30px;
		color: $black;
	}

	.s2 {
		font-size: 20px;
		line-height: 30px;
		color: $black;
	}

	.s3 {
		font-size: 18px;
		line-height: 29px;
		color: $black;
	}

	.s4 {
		font-size: 17px;
		line-height: 21px;
		color: $black;
	}

	.s4-5 {
		font-size: 15px;
		line-height: 19px;
		color: $black;
	}

	.s5 {
		font-size: 13px;
		line-height: 17px;
		color: $black;
	}

	.s6 {
		font-size: 13px;
		line-height: 17px;
		color: $black;
	}

	.s7 {
		font-size: 13px;
		line-height: 17px;
		color: $active-black;
	}

	::-webkit-scrollbar {
		// display: none;
		overflow-y: overlay;
		z-index: 99999;
		height: 5px;
		width: 3px;
		border-radius: 2px;
		background: $passive-gray;
		transition: all 0.3s ease;
	}

	::-webkit-scrollbar-thumb {
		background: $active-black;
		border-radius: 2px;
	}
}