@import "./envColors.scss";

$bg-white: #fafbfc;
$bg-grey: #f7f7f7;
$bg-fbfbfb: #fbfbfb;
$black: #3c3c3b;
$further-black: #212121;
$white: #ffffff;
$blue: #90c2e7;
$red: #e43a48;
$gray: #3e3e3e;
$linkedin-blue: #0072b1;
$link-blue: #4e8098;
$gray2: #999999;
$bgGray: #717171;
$story-bg: #24153d;
$story-cert-bg: #f3f3f3;
$story-purp-bg: #5800d6;
$story-email: #5a4f6d;
$shadow-gray: #00000040;
$shadow-gray2: #00000029;
$gray-blue: #ced3dc;
$helper-text-gray: #63668F;
$black: #333333;
$further-black: #513d3d;
$blue: #90c2e7;
$red: #e43a48;
$linkedin-blue: #0072b1;
$gray: #3e3e3e;
$gray2: #999999;
$bgGray: #717171;
$shadow-gray: #00000040;
$shadow-gray2: #00000029;
$gray-blue: #ced3dc;

// New Color Pallete
$white: #ffffff;
$secondary-bg: #f7f9ff;
$mobile-cert-bg: #f7f7f7;
$black: #000000;
$dark-blue: #192541;
$white-blue: #eaf0fd;

$primary-hover: #699fff;
$off-white: #fafafa;
$shadow-gray2: #00000010;
$tertiary-white: #D9D9D9;
$tertiary-text-color: #4D94FF;
$success-green: #07CB90;

// VCL
$shadow-black: rgba(99, 99, 99, 0.2);
$active-black: #000e27;
$cred-bg: #f0f6ff;
$tag-box-green: #21c96e;
$tag-box-red-bg: #e43a481a;
$tag-box-green-bg: #21c96e1a;
$tag-box-yellow: #ffb831;
$tag-box-yellow-bg: #ffb83133;
$orange: #ea4a01;
$text-gray: #aaaaaa;
$light-blue: #a0aec7;