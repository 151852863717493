@import url("https://fonts.googleapis.com/css2?family=Open+Sans:ital,wght@0,400;0,700;1,400;1,700&display=swap");
@import "./styles/fonts/IBM-Plex-Sans.css";

@font-face {
	font-family: "googleSans";
	font-display: swap;
	src: url("../src/fonts/GoogleSans-Regular.woff2") format("woff2");
	font-weight: 400;
	font-style: normal;
}
@font-face {
	font-family: "googleSans";
	font-display: swap;
	src: url("../src/fonts/GoogleSans-Bold.woff2") format("woff2");
	font-weight: 500;
	font-style: normal;
}
@font-face {
	font-family: "googleSans";
	font-display: swap;
	src: url("../src/fonts/GoogleSans-Italic.woff2") format("woff2");
	font-weight: 400;
	font-style: italic;
}
@font-face {
	font-family: "Gilroy";
	font-display: swap;
	src: url("../src/fonts/Gilroy-SemiBold/font.woff2") format("woff2");
	font-weight: 600;
}

@font-face {
	font-family: "Kolektif House";
	font-style: normal;
	font-weight: 400;
	font-display: swap;
	src: local("Kolektif House"),
		url(https://storage.googleapis.com/sertifier-storage/assets/AnyConv.com__KOLEKTIFHOUSE-Regular.ttf)
			format("truetype");
}
@font-face {
	font-family: "Gilroy";
	font-style: normal;
	font-weight: 600;
	font-display: swap;
	src: url(https://storage.googleapis.com/sertifier-storage/assets/Gilroy/Gilroy-SemiBold/font.woff)
		format("woff");
}
@font-face {
	font-family: "Gilroy";
	font-style: normal;
	font-weight: 500;
	font-display: swap;
	src: url(https://storage.googleapis.com/sertifier-storage/assets/Gilroy/Gilroy-Medium/font.woff)
		format("woff");
}
@font-face {
	font-family: "Gilroy";
	font-style: normal;
	font-weight: 400;
	font-display: swap;
	src: url(https://storage.googleapis.com/sertifier-storage/assets/Gilroy/Gilroy-Regular/font.woff)
		format("woff");
}

@font-face {
	font-family: "Alright Sans";
	src: url("../src/fonts/AlrightSans-Regular-v3.otf") format("opentype");
	font-weight: 400;
	font-style: normal;
}

@font-face {
	font-family: "Alright Sans";
	src: url("../src/fonts/AlrightSans-RegularItalic-v3.otf") format("opentype");
	font-weight: 400;
	font-style: italic;
}

@font-face {
	font-family: "Alright Sans";
	src: url("../src/fonts/AlrightSans-Bold-v3.otf") format("opentype");
	font-weight: 600;
	font-style: normal;
}

@font-face {
	font-family: "Alright Sans";
	src: url("../src/fonts/AlrightSans-BoldItalic-v3.otf") format("opentype");
	font-weight: 600;
	font-style: italic;
}

/* @font-face {
	font-family: "Gilroy";
	font-display: swap;
	src: url("../src/fonts/Gilroy-Regular/font.woff2") format("woff2");
	font-weight: 500;
} */

/* @font-face {
	font-family: "Gilroy";
	font-display: swap;
	src: url("../src/fonts/Gilroy-Medium/font.woff2") format("woff2");
	font-weight: 600;
	font-style: normal;
} */

/* @font-face {
	font-family: "Gilroy";
	src: local("Gilroy"),
		url("../src/fonts/Gilroy-ExtraBold.otf") format("opentype");
	font-style: normal;
	font-weight: bold;
} */

body {
	margin: 0;
	font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen",
		"Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue",
		sans-serif;
	-webkit-font-smoothing: antialiased;
	-moz-osx-font-smoothing: grayscale;
}

code {
	font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
		monospace;
}
